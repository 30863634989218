import { useState, useRef, useEffect } from 'react';
import { useTorchLight } from './hooks/useTorchLight';

export function App() {
  const trackRef = useRef(null);
  const [error, setError] = useState(null);
  const [torch, setTorch] = useState(false);

  useEffect(() => {
    const loadStream = async () => {
      const SUPPORTS_MEDIA_DEVICES = 'mediaDevices' in navigator;
      if (!SUPPORTS_MEDIA_DEVICES) {
        console.log('mediaDevices not supported.');
        setError('mediaDevices not supported.');
        return;
      }

      const devices = await navigator.mediaDevices.enumerateDevices();

      const cameras = devices.filter((device) => device.kind === 'videoinput');
      if (cameras.length === 0) {
        console.log('No camera found on this device.');
        setError('No camera found on this device.');
        return;
      }
      // Create stream and get video track
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
        }
      });
      const track = stream.getVideoTracks()[0];
      trackRef.current = track;

      //Create image capture object and get camera capabilities
      const imageCapture = new ImageCapture(track);
      const capabilities = await imageCapture.getPhotoCapabilities();
      const torchSupported = !!capabilities.torch || (
        'fillLightMode' in capabilities &&
        capabilities.fillLightMode.length != 0 &&
        capabilities.fillLightMode != 'none'
      );
      if (!torchSupported) {
        console.log('No torch found on this device.');
        setError('No torch found on this device.');
        return;
      }
    }
    loadStream();
  }, []);

  const clickLight = () => {
    const newTorch = !torch;
    trackRef.current.applyConstraints({
      advanced: [{
        torch: newTorch
      }]
    });
    setTorch(newTorch);
  }

  const clickVibrate = () => {
    if (navigator.vibrate) {
      console.log("Vibrating");
      navigator.vibrate(1000);
      setError(null);
    } else {
      console.log("Vibrate not available");
      setError("Vibrate not available");
    }
  }

  return (
    <>
      <h1>Hello world!?</h1>
      {error && (
        <p>{error}</p>
      )}
      <button onClick={clickVibrate}>Vibrate</button>
      <button onClick={clickLight}>Toggle Light</button>
    </>
  );
}
