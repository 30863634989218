import { useState, useCallback } from 'react';

export const isFunction = (val) => typeof val === 'function';

const defaultOptions = {
  debug: false,
  vibrate: 70,
};

export const useTorchLight = (
  stream,
  {
    debug,
    vibrate,
    onError,
    onSuccess,
  } = defaultOptions
) => {
  const [on, setOn] = useState(false);

  const turnOn = useCallback(async () => {
    try {
      window.navigator.vibrate([vibrate]);

      if (stream) {
        const [track] = stream.getTracks();

        if (track instanceof MediaStreamTrack) {
          try {
            // ImageCapture is a new specification supported in modern browsers
            const imageCapture = new ImageCapture(track);
            const capabilities = await imageCapture.getPhotoCapabilities();

            if (capabilities.fillLightMode.includes('flash')) {
              await track.applyConstraints({
                advanced: [{ torch: true }],
              });

              if (isFunction(onSuccess)) {
                onSuccess({ track, on });
              }
            }
          } catch (err) {
            console.log(`[UseTorchLight]: error ${err.message}`);

            // If errored, we can go to fallback forcing to apply the constraints
            try {
              await track.applyConstraints({
                advanced: [{ torch: true }],
              });
            } catch (err) {
              console.log(`[UseTorchLight]: error ${err.message}`);

              if (isFunction(onError)) {
                onError(err);
              }
            }

            if (isFunction(onError)) {
              onError(err);
            }
          }

          setOn(true);
        }
      }
    } catch (err) {
      console.log(`[UseTorchLight]: error ${err.message}`);
    }
  }, [stream, vibrate, debug]);

  const turnOff = useCallback(async () => {
    window.navigator.vibrate([vibrate]);

    if (stream) {
      const pc = new RTCPeerConnection();

      // Older way for browsers
      if ('applyConstraints' in stream) {
        try {
          await stream?.applyConstraints({
            advanced: [{ torch: false }],
          });

          if ('addStream' in pc) {
            pc?.addStream(stream);
          }

          if (isFunction(onSuccess)) {
            onSuccess({ stream, on });
          }
        } catch (err) {
          console.log(`[UseTorchLight]: error ${err.message}`);

          if (isFunction(onError)) {
            onError(err);
          }
        }

        // New way for modern browsers
      } else {
        const tracks = stream.getTracks();

        for (let track of tracks) {
          try {
            await track.applyConstraints({
              advanced: [{ torch: false }],
            });

            pc.addTrack(track, stream);

            if (isFunction(onSuccess)) {
              onSuccess({ track, on });
            }
          } catch (err) {
            console.log(`[UseTorchLight]: error ${err.message}`);

            if (isFunction(onError)) {
              onError(err);
            }
          }
        }
      }

      setOn(false);
    }
  }, [stream, vibrate, debug]);

  return [on, on ? turnOff : turnOn];
};
